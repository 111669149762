import React, { useEffect, useState } from "react";
import logo from "./logo.svg";
import Btn from "./components/Button";
import InputField from "./components/InputField";
import Collapsible from "./components/Collapsible";
import PantherImg from "./images/pinkPanther.png";

import { Container, Row, Col } from "react-bootstrap";

import "bootstrap/dist/css/bootstrap.css";
import styles from "./App.module.scss";

import { firestore } from "./firebase";
import firebase, { provider } from "./firebase";

function App() {
  const [todoItems, setToDoItems] = useState([]);
  const [newItem, setNewItem] = useState("");
  const [fieldsVisible, toggleVisibility] = useState(false);
  const [listVisible, toggleList] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    if (user) {
      fetchToDos();
    }
  }, [user]);

  useEffect(() => {
    getUser();
  }, []);

  const signInWithRedirect = () => {
    firebase.auth().signInWithRedirect(provider);
  };

  const getUser = () => {
    firebase
      .auth()
      .getRedirectResult()
      .then((result) => {
        if (result.credential) {
          const token = result.credential.accessToken;
        }
        console.log(result);
        const user = result.user;
        setUser(user);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        const email = error.email;
        const credential = error.credential;
      });
  };

  const signOut = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        setUser(null);
        alert("You have signed out");
      })
      .catch((error) => {
        alert("Oh no an error :( " + error);
      });
  };

  const getSignInButtons = () => {
    if (user) {
      return (
        <>
          <Btn handleClick={signOut} text="Sign Out" type="danger" />
          {/* <img src={user.photoURL} alt={`${user.displayName}'s Face`} /> */}
        </>
      );
    } else {
      return (
        <Btn handleClick={signInWithRedirect} text="Sign In" type="success" />
      );
    }
  };

  const fetchToDos = () => {
    if (user) {
      firestore
        .collection("todo-items")
        .doc(user.uid)
        .get()
        .then((doc) => {
          console.log(doc.data());
          const retreivedItems = doc.data().items;
          setToDoItems(retreivedItems);
        })
        .catch((err) => console.log(err));
    }
  };

  const addItem = () => {
    const newItems = [...todoItems, newItem];

    const newDoc = {
      items: newItems,
    };

    firestore
      .collection("todo-items")
      .doc(user.uid)
      .set(newDoc)
      .then(() => {
        console.log("well done");
        fetchToDos();
      })
      .catch((err) => console.log(err));
  };

  const removeItem = (item) => {
    const newItems = [...todoItems];
    const position = newItems.indexOf(item);
    newItems.splice(position, 1);

    const newDoc = {
      items: newItems,
    };

    firestore
      .collection("todo-items")
      .doc(user.uid)
      .set(newDoc)
      .then(() => {
        console.log("Item removed");
        fetchToDos();
      })
      .catch((err) => console.log(err));
  };

  const inputFields = fieldsVisible ? (
    <>
      <section className={styles.inputFields}>
        <InputField
          setItem={(title) => setNewItem({ ...newItem, name: `${title}` })}
          placeholder="Item name"
          type="text"
        />
        <InputField
          setItem={(info) => setNewItem({ ...newItem, info: `${info}` })}
          placeholder="Additonal information"
          type="text"
        />
        <InputField
          setItem={(creationDate) =>
            setNewItem({ ...newItem, creationDate: `${creationDate}` })
          }
          placeholder="Creation date"
          onfocus={(e) => (e.target.type = "date")}
          onblur={(e) => (e.target.type = "text")}
        />
        <InputField
          setItem={(completionDate) =>
            setNewItem({ ...newItem, completionDate: `${completionDate}` })
          }
          placeholder="Completion date"
          onfocus={(e) => (e.target.type = "date")}
          onblur={(e) => (e.target.type = "text")}
        />
        <InputField
          setItem={(image) => setNewItem({ ...newItem, image: `${image}` })}
          placeholder="Image URL"
          type="url"
        />
        <Btn
          handleClick={() => addItem()}
          text={"Add item"}
          type="outline-success"
          margin="m-2"
        />
      </section>
    </>
  ) : null;

  const printList = () => {
    if (listVisible) {
      return (
        <>
          <Container>
            <Row>
              {todoItems.map((item) => (
                <>
                  <Col className="p-2 col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                    <div className={styles.item}>
                      <h3>{item.name}</h3>
                      {item.info ? (
                        <p>
                          <Collapsible text={item.info} />
                        </p>
                      ) : null}
                      {item.creationDate ? (
                        <p>{`Date created: ${item.creationDate}`}</p>
                      ) : null}
                      {item.completionDate ? (
                        <p>{`Deadline: ${item.completionDate}`}</p>
                      ) : null}
                      {item.image ? (
                        <img
                          className={styles.picture}
                          src={`${item.image}`}
                        ></img>
                      ) : null}
                      <div>
                        <Btn
                          type="danger"
                          handleClick={() => removeItem(item)}
                          text="Delete"
                          btnSize="sm"
                          margin="mt-2"
                        />
                      </div>
                    </div>
                  </Col>
                </>
              ))}
            </Row>
          </Container>
        </>
      );
    }
  };

  const buttons = (
    <div className={styles.buttons}>
      <Btn
        type="primary"
        handleClick={() => toggleList(!listVisible)}
        text="Show my list"
        margin="m-2"
      />
      <Btn
        type="outline-secondary"
        handleClick={() => toggleVisibility(!fieldsVisible)}
        text="Add new item"
        margin="m-2"
      />
    </div>
  );

  const dashboard = user ? (
    <section className={styles.section}>
      {buttons}
      {inputFields}
      {printList()}
    </section>
  ) : null;
  // <div className={styles.modal}>
  //   <div className={styles.modalContent}>
  //     <div className={styles.modalHeader}>
  //       <span className={styles.close}>&times;</span>
  //       <h2>Modal Header</h2>
  //     </div>
  //     <div className={styles.modalBody}>
  //       <p>Some text in the Modal Body</p>
  //       <p>Some other text...</p>
  //     </div>
  //     <div className={styles.modalFooter}>
  //       <h3>Modal Footer</h3>
  //     </div>
  //   </div>
  // </div>

  return (
    <>
      <div className={styles.sign}>{getSignInButtons()}</div>
      <header className={styles.header}>
        <img src={PantherImg} />
        <h3>THE</h3>
        <h2>PINK PANTHER</h2>
        <h2 className={styles.bottom}>LIST</h2>
      </header>

      {dashboard}
      {/* <button id="myBtn">Open Modal</button> */}
    </>
  );
}

export default App;
